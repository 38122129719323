import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { navigate } from 'hookrouter';
import {
  get as _get
} from 'lodash';

import {
  DefaultRoute,
  Navigations
} from '../../settings/nav-config';
import { getAtError } from '../../settings/error';

import { AuthActionType } from './auth-action-types';
import { UserActionType } from '../user/user-action-types';

import {
  atLogin,
  atLogout,
  atResetPasswordLink,
  atResetPasswordTokenValidate,
  atResetPassword,
  atUpdatePassword,
  atCompleteSetup,
  atCompleteConsent,
} from '../../services/at-app-auth-api';

function* restoreSession(action: any) {
  const authData = {
    token_type: sessionStorage.getItem('at_api_token_type') || '',
    access_token: sessionStorage.getItem('at_api_access_token') || '',
    expires_in: parseInt(sessionStorage.getItem('at_api_expires_in') || '0'),
    refresh_token: sessionStorage.getItem('at_api_refresh_token') || '',
  };
  yield put({ type: AuthActionType.LoginSuccess, payload: { data: authData }});
  yield put({ type: UserActionType.RestoreUserInfo });
}

function* login(action: any) {
  try {
    sessionStorage.removeItem('at_api_token_type');
    sessionStorage.removeItem('at_api_access_token');
    sessionStorage.removeItem('at_api_expires_in');
    sessionStorage.removeItem('at_api_refresh_token');
    const data = yield call(atLogin, action.payload.username, action.payload.password);
    yield put({ type: AuthActionType.LoginSuccess, payload: data });
    yield put({ type: UserActionType.GetUserInfo });
    // navigate(DefaultRoute);
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.LoginFailure, payload: error });
  }
}

function* autoLogin(action: any) {
  try {
    sessionStorage.removeItem('at_api_token_type');
    sessionStorage.removeItem('at_api_access_token');
    sessionStorage.removeItem('at_api_expires_in');
    sessionStorage.removeItem('at_api_refresh_token');
    let data = {
      token_type: "Bearer",
      access_token: action.payload,
      expires_in: 86400,
    }
    yield put({ type: AuthActionType.LoginSuccess, payload: {data:data} });
    yield put({ type: UserActionType.GetUserInfo });
    // navigate(DefaultRoute);
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.LoginFailure, payload: error });
  }
}

function* logout(action: any) {
  try {
    yield call(atLogout);
    yield put({ type: 'ResetStore', payload: null });
    navigate('/login');
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
  }
}

function* completeConsent(action: any) {
    try {
      const {
        username,
        code
      } = action.payload;
      const data = yield call(atCompleteConsent, username, code);
      yield put({ type: AuthActionType.CompleteConsentSuccess, payload: data });
      toast.success(`Consent has been registered for this patient.`);
      navigate(`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.consentSuccess}`);
    } catch (err) {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: AuthActionType.CompleteConsentFailure, payload: error });
      navigate(`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.consentFailure}`);
    }
}

function* resetPasswordLink(action: any) {
  try {
    const data = yield call(atResetPasswordLink, action.payload.email);
    yield put({ type: AuthActionType.ResetPasswordLinkSuccess, payload: data });
    toast.success(`Check your email for further instructions.`);
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.ResetPasswordLinkFailure, payload: error });
  }
}

function* resetResetPasswordTokenValidate(action: any) {
  try {
    const {
      username,
      code
    } = action.payload;
    const data = yield call(atResetPasswordTokenValidate, username, code);
    yield put({ type: AuthActionType.ResetPasswordTokenValidateSuccess, payload: data });

    if (data.data===false) {
      toast.error(`Your token is invalid.`);
      navigate(`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.resetPasswordLink}`);
    }
    else
    {
      toast.success(`Your token is valid.`);
    }
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.ResetPasswordTokenValidateFailure, payload: error });
    navigate(`${Navigations.auth.accountSecurity.root}/${Navigations.auth.accountSecurity.resetPasswordLink}`);
  }
}

function* resetPassword(action: any) {
  try {
    const {
      username,
      password,
      code
    } = action.payload;
    const data = yield call(atResetPassword, username, password, code);
    yield put({ type: AuthActionType.ResetPasswordSuccess, payload: data });
    toast.success(`Your password has been updated.`);
    yield put({ type: AuthActionType.Login, payload: { username, password } });
    yield navigate('/');
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.ResetPasswordFailure, payload: error });
  }
}

function* completeSetup(action: any) {
  try {
    const {
      username,
      password,
      code
    } = action.payload;
    const data = yield call(atCompleteSetup, username, password, code);
    yield put({ type: AuthActionType.CompleteSetupSuccess, payload: data });
    toast.success(`Your account is ready for use!`);
    yield put({ type: AuthActionType.Login, payload: { username, password } });
    yield navigate('/');
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.CompleteSetupFailure, payload: error });
  }
}

function* updatePassword(action: any) {
  try {
    const {
      username,
      currentpassword,
      newpassword
    } = action.payload;
    const data = yield call(atUpdatePassword, username, currentpassword, newpassword);
    yield put({ type: AuthActionType.UpdatePasswordSuccess, payload: data });
    toast.success(`Your password has been updated.`);
  } catch (err) {
    const error = getAtError(err);
    toast.error(`${error}`);
    yield put({ type: AuthActionType.UpdatePasswordFailure, payload: error });
  }
}

const authSagas = [
  takeLatest(AuthActionType.AutoLogin, autoLogin),
  takeLatest(AuthActionType.Login, login),
  takeLatest(AuthActionType.RestoreSession, restoreSession),
  takeLatest(AuthActionType.Logout, logout),
  takeLatest(AuthActionType.CompleteConsent, completeConsent),
  takeLatest(AuthActionType.ResetPasswordLink, resetPasswordLink),
  takeLatest(AuthActionType.ResetPasswordTokenValidate, resetResetPasswordTokenValidate),
  takeLatest(AuthActionType.ResetPassword, resetPassword),
  takeLatest(AuthActionType.UpdatePassword, updatePassword),
  takeLatest(AuthActionType.CompleteSetup, completeSetup),
];

export {
  authSagas,
}